import { navigate } from 'gatsby';
import React, { memo, useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import firebase from 'gatsby-plugin-firebase';
import ShortUniqueId from 'short-unique-id';
import LoadingScreen from '../../components/router/LoadingScreen';
import Wrapper from '../../components/shared/Wrapper';
import initialState from '../../data/initialState.json';
import initialState2 from '../../data/initialState2.json';

const getProperTemplate = (template) => {
  switch (template) {
    case 'stockholm':
      return 'onyx';
    case 'new_york':
      return 'pikachu';
    case 'vienna':
      return 'gengar';
    case 'sydney':
      return 'castform';
    case 'london':
      return 'onyx';
    case 'dublin':
      return 'glalie';
    case 'moscow':
      return 'onyx';
    case 'amsterdam':
      return 'pikachu';
    case 'madrid':
      return 'celebi';
    case 'santiago':
      return 'onyx';
    case 'singapore':
      return 'onyx';
    case 'oslo':
      return 'onyx';
    case 'paris':
      return 'glalie';
    case 'berlin':
      return 'pikachu';
    case 'vancouver':
      return 'onyx';
    case 'tokyo':
      return 'onyx';
    case 'milan':
      return 'pikachu';
    case 'barcelona':
      return 'pikachu';
    case 'copenhagen':
      return 'onyx';
    default:
      return 'onyx';
  }
};

const getProperTemplate2 = (template) => {
  switch (template) {
    case 'stockholm':
      return 'stockholm2';
    case 'new_york':
      return 'newyork2';
    default:
      return 'newyork2';
  }
};

const Create = ({ user, type, template }) => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const createResume = async () => {
    const id = uuid();
    const name = 'My Resume';
    const createdAt = firebase.database.ServerValue.TIMESTAMP;
    const resumeTemplate = getProperTemplate(params.template);
    console.log(params.template);
    console.log(resumeTemplate);

    let firstName;
    let lastName;

    const resume = {
      ...initialState,
      id,
      name,
      user: user.uid,
      profile: {
        ...initialState.profile,
        firstName: firstName || '',
        lastName: lastName || '',
      },
      metadata: {
        ...initialState.metadata,
        template: resumeTemplate,
      },
      createdAt,
      updatedAt: createdAt,
    };

    console.log(resume);
    firebase.database().ref(`resumes/${id}`).set(resume);

    return id;
  };

  const createCover = async () => {
    const id = uuid();
    const name = 'My Cover Letter';
    const localUser = JSON.parse(localStorage.getItem('user'));
    console.log(localUser);
    console.log(template);
    const coverTemplate = getProperTemplate2(template);
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    let firstName;
    let lastName;

    const cover = {
      ...initialState2,
      id,
      name,
      user: localUser.uid,
      // preview,
      profile: {
        ...initialState2.profile,
        firstName: firstName || '',
        lastName: lastName || '',
      },
      metadata: {
        ...initialState2.metadata,
        template: coverTemplate,
      },
      createdAt,
      updatedAt: createdAt,
    };

    console.log(cover);
    firebase.database().ref(`covers/${id}`).set(cover);

    return id;
  };

  useEffect(() => {
    (async () => {
      if (type === 'resume') {
        // console.log('start create resume');
        const id = await createResume();
        // console.log('end create resume');
        setLoading(false);
        navigate(`/app/builder/${id}`);
        // console.log('navigate resume builder');
      } else if (type === 'cletter') {
        // console.log('start create cover letter');
        const id = await createCover();
        // console.log('end create cover letter');
        setLoading(false);
        navigate(`/app/clbuilder/${id}`);
        // console.log('navigate cover letter builder');
      } else {
        setLoading(false);
        navigate(`/app/dashboard`);
      }
    })();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Wrapper>
      <div className="xl:px-0 text-center md:text-left">
        Going to ResumesMadeHere Resume Builder
      </div>
    </Wrapper>
  );
};

export default memo(Create);
